import {Component, default as React} from "react";
import {FiatSelectButtons} from "../common/FiatSelectButtons";
import {DiffBlock} from "./DiffBlock";
import {ApiData} from "../../models/ApiData";
import {ExchangeSession} from "../../models/ExchangeSession";
import {UrlUtils} from "../../utils/UrlUtils";


export class RatesDiffContent extends Component {
    state = {
        symbol: "USD",
        data_list: [{}]
    }

    componentDidMount(): void {
        this.updateRates(this.state.symbol);
    }

    updateRates = (symbol: string) =>
    {
        ApiData.exchangeApiRequest({symbol: symbol}, 'get_rates_fiat_symbol').then((result) => {
            if(result.success)
                this.setState({
                    data_list: result.data
                });
        });
    }

    onSelectFiatSymbol = (symbol: string) =>
    {
        this.setState({symbol: symbol});
        this.updateRates(symbol);
    };

    onSelectCryptoSymbol = (symbol: string) =>
    {
        ExchangeSession.data = {
            fiat_select: this.state.symbol,
            fiat_input: 250,
            crypto_select: symbol,
            step: 1
        }
        UrlUtils.redirectTo('exchange?step=1');
    };

    render = () => (
        <section className="rates">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="title rates__title">Cryptocurrency Rates</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="rates__currency-list">
                            <span className="rates__currency_name">Price:</span>
                            <FiatSelectButtons onSelect={this.onSelectFiatSymbol} className={"rates__currency_item"}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {this.state.data_list.length > 1 && this.state.data_list.map((e: any, index:number) => <DiffBlock onSelect={this.onSelectCryptoSymbol} symbol={e.symbol} rate={e.rate} rate_symbol={this.state.symbol} change={e.change}/>)}
                </div>
            </div>
        </section>
    );
}