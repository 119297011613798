import {Component, default as React} from "react";

import {Promo} from "../login/Promo";
import {EmailVerifyModal} from "../login/EmailVerifyModal";
import {Modal} from "../Modal";
import ContactForm from "./ContactForm";
import {PromoContacts} from "../recovery/PromoContacts";


export const ContactContent = () =>
    <>
        <div className="body__steps contact_us_wrap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="steps__box">
                            <div className="row no-gutters">
                                <div className="steps__part_big steps__part_big_bg">
                                    <div className="steps__box_light">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 className="steps__box_title">Submit a tiket </h3>
                                            </div>
                                        </div>

                                        {ContactForm()}
                                    </div>
                                </div>

                                <PromoContacts />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>