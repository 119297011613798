import {Component, default as React} from "react";

export interface IModalProps {
    id: string;
    footer_text?: string;
}

export class Modal extends Component<IModalProps, any> {

    toggle = () =>
    {
        // @ts-ignore
        $("#" + this.props.id).modal('toggle');
    };



    render = () => (
            <div ref="toggleModal" data-toggle={"modal"} className="modal fade standart" id={this.props.id} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-standart" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><img src="img/payment/icons/close.svg" alt="close" /></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal__content text-center">

                                {this.props.children}

                            </div>
                        </div>
                    </div>
                    {this.props.footer_text &&
                    <div className="modal-footer modal-footer_bg text-center">
                        <p className="modal__text">{this.props.footer_text}</p>
                    </div>
                    }
                </div>
            </div>
    );
}