import * as React from "react";

export const EmailVerifyModal = () =>
    <>
        <div className="modal fade standart" id="email_sent"  role="dialog" aria-labelledby="email_sent" aria-hidden="true">
            <div className="modal-dialog modal-standart" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src="img/payment/icons/close.svg" alt="close" /></span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="modal__content text-center">
                            <img src="img/03.svg" alt="icons" className="modal__icon" />
                                <h3 className="modal__title">Verification email was sent</h3>
                                <p className="modal__text">Email was sent to <strong
                                    className="user_email">you</strong> with the confirmation link.
                                    Follow the link to be redirected to Wisenex. </p>
                        </div>
                    </div>
                    <div className="modal-footer modal-footer_bg text-center">
                        <p className="modal__text">To continue confirm your email first.</p>
                    </div>
                </div>
            </div>
        </div>
    </>
