import * as React from 'react';

import {Component} from "react";
import {ApiData} from "../../models/ApiData";
import {EventUtils} from "../../utils/EventUtils";

export interface ISelectorApiDataState {
    data_list: any[];
}

export interface SelectorApiDataProps {
    selectedText?: string;
    key_equals_value:boolean
    type: string,
    param?: string,
    onLoaded?: () => void,
    onReloaded?: (firstValue: string) => void
}

export default class OptionsApiData extends React.Component<SelectorApiDataProps, ISelectorApiDataState>  {
    state = {
        data_list: [{}]
    }

    //unsubscribeUpdateSelectorList: () => void;


    public componentDidMount(): void {
        /*
        this.unsubscribeUpdateSelectorList = EventUtils.bus.subscribe(EventUtils.updateSelectorList,  event => {
            if(this.props.type == event.payload.type)
                this.updateList(event.payload.payment_id);
        });*/

        this.updateList(true);
    };

    updateList = async (isFirst: boolean) =>
    {
        const data = await ApiData.getSelectorList(this.props.type, this.props.param);
        const curSelected = this.getCurrentSelected();

        this.setState({
            data_list: data,
        }, isFirst ?
            this.props.onLoaded
            :
            () => {
                if(this.props.onReloaded && this.state.data_list.length > 0) {
                    let newSelected = this.getCurrentSelected();
                    if(curSelected != newSelected)
                        this.props.onReloaded(newSelected);
            }
        });
    }

    getCurrentSelected = () =>
    {
        return this.state.data_list.length > 0 ? this.state.data_list[0]['value'] : null;
    }

    public componentWillUnmount(): void {
        //this.unsubscribeUpdateSelectorList();
    }

    public async componentDidUpdate(prevProps: Readonly<SelectorApiDataProps>, prevState: Readonly<ISelectorApiDataState>, snapshot?: any): Promise<void> {
        if (prevProps.param != this.props.param) {
            await this.updateList(false);
        }
    }

    public render = () => {
        return <>
            {this.state.data_list.map((k: any, index: number) => <option key={index} value={this.props.key_equals_value ? k.key : k.value} >{k.key}</option>)}
        </>
    }
}