import * as React from "react";
import {Header} from "../components/Header";
import {SignUpContent} from "../components/signup/SignUpContent";
import {Footer} from "../components/Footer";
import {ToastContainer} from "react-toastify";
import {Component} from "react";
import {UrlUtils} from "../utils/UrlUtils";

export class SignUpPage extends Component
{
    public componentDidMount(): void {
        let email = UrlUtils.getUrlParam('email');
        if(email)
            $("#email").val(email);
    }

render = () => <>
        <Header isDark={false} />
        <ToastContainer autoClose={7000} />

        <SignUpContent/>
        <Footer isDark={false} />
    </>
}