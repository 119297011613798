import {Component, default as React} from "react";
import {ExchangeStepsLine} from "./ExchangeStepsLine";
import ExchangeSummaryDetails from "./ExchangeSummaryDetails";
import {ExchangeStep2Form} from "./ExchangeStep2Form";
import {StringUtils} from "../../utils/StringUtils";
import {ACTIONS, ExchangeSession} from "../../models/ExchangeSession";
import {ApiData, getApiDataPromise} from "../../models/ApiData";
import {toast} from "react-toastify";
import {ExClient} from "../../models/ExClient";
import {UrlUtils} from "../../utils/UrlUtils";

export class ExchangeStep2PersonalContent extends Component {
    state = {
        fiatCurrency: "EUR",
        cryptoCurrency: "BTC",
        wallet: '',
        fiat_amount: 0
    }

    tempEmailVerifiedWithNewSession = async (query: URLSearchParams) =>
    {
        if(query.has('email') && query.has('fiat_input') && query.has('crypto_input')) {
            const email = query.get('email');
            let result = await ApiData.clientApiRequest({
                email: email,
                s_id: ExClient.shop_id,
            }, "is_verified_temp_email");
            if (result.success && result.data) {
                let exchange_data:any = ExchangeSession.getExchangeState();
                if(!exchange_data)
                    exchange_data = {};
                query.forEach(function(value, key) {
                    exchange_data[key] = value;
                });

                ExchangeSession.email_temp = exchange_data['email'];
                ExchangeSession.action = ACTIONS.Exchange;
                ExchangeSession.data = exchange_data;

                if(exchange_data['p_id'] && exchange_data['s_id'])
                {
                    ExClient.payment_id = exchange_data['p_id'];
                    ExClient.shop_id = exchange_data['s_id'];
                }

                console.log("EmailVerifyExchange: SAVE STEP", exchange_data);
                return true;
            }
            else
            {
                ExchangeSession.cancel();
                UrlUtils.redirectTo("exchange");
            }
        }
        return false;
    }

    tempEmailVerify = (query: URLSearchParams) =>
    {
        if(ExchangeSession.action == ACTIONS.EmailVerifyExchange && query.has('email'))
        {
            ExchangeSession.email_temp = query.get('email');
            ExchangeSession.action = ACTIONS.Exchange;
            ExchangeSession.saveDataParam('step', "2");
            return true;
        }
        return false;
    }

    public async componentDidMount(): Promise<void> {
        const query = new URLSearchParams(window.location.search);
        if(!this.tempEmailVerify(query))
            await this.tempEmailVerifiedWithNewSession(query);

        ExchangeSession.redirectIfWrongStep("2");

        if(!StringUtils.isEmail(ExchangeSession.email_temp)) {
            ExchangeSession.cancel();
            UrlUtils.redirectTo("exchange");
        }

        const state = ExchangeSession.getExchangeState();
        if(state)
            this.setState(state);
    }

    onSubmitForm = async function (data: any)
    {
        data.email = ExchangeSession.email_temp;
        console.log("step2 2", data);
        data.shop_id = ExClient.shop_id;
        var result = await getApiDataPromise(data,"client", "register_form");

        if(result.success) {
            toast.success("Registered!");
            let exchange_data = ExchangeSession.data;
            exchange_data['step'] = "3";
            ExchangeSession.action = ACTIONS.Exchange;
            ExchangeSession.data = exchange_data;

            await ExClient.sendRegEvent("exchange");
            UrlUtils.redirectTo("exchange?step=3");
        }
        else
            toast.error(result.message);
    };

    render() {
        return (
                <div className="body__steps">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 steps__dots_bg">
                                <ExchangeStepsLine step_id={1} />
                            </div>
                            <div className="col-lg-10">
                                <div className="steps__box steps__box_boxing">
                                    <div className="row no-gutters">
                                        <div className="steps__part_big">
                                            <div className="steps__box_light">
                                                <ExchangeStep2Form  onSubmit={this.onSubmitForm}/>
                                            </div>
                                        </div>
                                        <div className="steps__part_small">
                                            <div className="steps__box_dark">
                                                <ExchangeSummaryDetails  wallet={this.state.wallet} fiat_amount={this.state.fiat_amount} crypto_currency={this.state.cryptoCurrency} fiat_currency={this.state.fiatCurrency} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}