import {ExchangeSession} from "./ExchangeSession";
import {ApiData, getApiDataPromise} from "./ApiData";
import {ExDocumentType} from "./types/ExDocumentType";
import {StringUtils} from "../utils/StringUtils";
import {UrlUtils} from "../utils/UrlUtils";
import {DefaultValues} from "./DefaultValues";
import {NetUtils} from "../utils/NetUtils";

enum Verification {
    VERIFICATION_PHONE_EXCLIENT = 1,
    VERIFICATION_EMAIL_EXCLIENT = 2,
    VERIFICATION_PASSPORT_EXCLIENT= 4,
    VERIFICATION_CARD_EXCLIENT = 8,
    VERIFICATION_UTILITY_BILL_EXCLIENT = 16,
    VERIFICATION_SELFIE_CARD_EXCLIENT = 32,
    VERIFICATION_SELFIE_PASSPORT_EXCLIENT = 64
}

export class ExClient {

    public static init()
    {
        const payment_id = UrlUtils.getUrlParam('p_id');
        if(payment_id)
            this.payment_id = payment_id;

        const payment_name = UrlUtils.getUrlParam('p_name')
        if (payment_name)
            this.payment_name = payment_name

        const shop_id = UrlUtils.getUrlParam('s_id');
        if(shop_id)
            this.shop_id = shop_id;

        const tid = UrlUtils.getUrlParam('tid');
        if(tid)
            this.tid = tid;
    }

    static get shop_id(): string {
        return localStorage.getItem("ex_client.shop_id");
    }

    static set shop_id(value: string) {
        if (value === undefined)
            throw new Error('Please enter valid value');
        localStorage.setItem("ex_client.shop_id", value);
    }

    static get tid(): string {
        return localStorage.getItem("ex_client.tid");
    }

    static get isAuthorized(): boolean {
        return Number(localStorage.getItem("ex_client.login_id")) > 0;
    }

    static set tid(value: string) {
        if (value === undefined)
            throw new Error('Please enter valid value');
        localStorage.setItem("ex_client.tid", value);
    }

    static get payment_id(): string {
        return localStorage.getItem("ex_client.payment_id");
    }

    static set payment_id(value: string) {
        if (value === undefined)
            throw new Error('Please enter valid value');
        localStorage.setItem("ex_client.payment_id", value);
    }

    static get payment_name(): string {
        return localStorage.getItem('ex_client.payment_name')
    }

    static set payment_name(value: string) {
        if (value === undefined)
            throw new Error('Please enter valid value')
        localStorage.setItem('ex_client.payment_name', value)
    }

    public static redirectLogin()
    {
        if(ExchangeSession.action != null) {
            let data = ExchangeSession.data;

            let url = "/" + ExchangeSession.action + "?step=" + (data['step'] ? data['step'] : 1);
            ExchangeSession.action = null;
            UrlUtils.redirectTo(url);
        }
        else
            UrlUtils.redirectTo("client");
    }

    public static async checkLogin(): Promise<any>
    {
        const result = await NetUtils.getApiDataPromiseResultWithRetry({},"client", "check_login");
        localStorage.setItem("ex_client.login_id", result);
        return result;
    }

    public static async hasWallet(): Promise<boolean> {
        return NetUtils.getApiDataPromiseResultWithRetry({},"exchange", "has_wallet");
    }

    static async isValidWallet(body: any): Promise<boolean> {
        return NetUtils.getApiDataPromiseResultWithRetry(body,"exchange", "is_valid_wallet");
    }

    public static async canUpload(): Promise<boolean>
    {
        return NetUtils.getApiDataPromiseResultWithRetry({},"client", "check_any_login");
    }

    public static async getVerifyFlags()
    {
        return NetUtils.getApiDataPromiseResultWithRetry({},"client", "get_verify_flags");
    }

    public static async getVerifyInfo() {
        return NetUtils.getApiDataPromiseResultWithRetry({}, 'client', 'get_verify_info')
    }

    public static isVerified(flags: any)
    {
        if(flags !== false)
            return (flags & Verification.VERIFICATION_SELFIE_CARD_EXCLIENT) && (flags & Verification.VERIFICATION_PASSPORT_EXCLIENT);
        return false;
    }

    public static async uploadDocument(file: any, type_id: string) : Promise<any>
    {
        const data = new FormData();
        data.append('doc_upload', file);
        data.append("type_id", type_id);
        data.append('action', 'upload_document');

        const result = await fetch(
            DefaultValues.PAY + 'api/exclient/verifications.htm',
            {
                method: 'post',
                body: data,
                credentials: "include"
            });
        return result.json();
    }

    public static async login(login: string, password: string)
    {
        var result = await getApiDataPromise({
            login: login,
            password: password
        },"client", "login");

        if(result.success)
            return true;
        else
            throw new Error(result.message);
    }

    static async verifyTempMail(data: any) {
        var result = await getApiDataPromise(data,"client", "verify_temp_email");
        return result;
    }

    public static async sendRegEvent(where: string)
    {
        let url = 'https://up.billsfork.com/track_pb?more_info=' + where;

        if(ExClient.shop_id)
            url += "&source=" + ExClient.shop_id;

        if(ExClient.tid)
            url += "&transaction_id=" + ExClient.tid;

        try
        {
            await fetch(url);
        } catch(e) {
            console.log("can's send info about registration", e);
        }
    }

    static async logout() {
        localStorage.clear();
        var result = await getApiDataPromise({},"client", "logout");
        if(result.success)
            UrlUtils.redirectTo(DefaultValues.HOME + "login");
    }
}