import {Component, default as React} from "react";

export interface ITimerProps {
    count_down: number;
    text: string;
    onUpdate:  () => void;
    enabled?:boolean;
    className: string;
}

export class Timer extends Component<ITimerProps, {}> {
    state = {
        timer_countdown: this.props.count_down
    }
    private timer: any;

    public componentDidMount(): void {
        if(this.props.enabled === false)
            return;

            this.timer = setInterval(() => {
                if (this.state.timer_countdown > 0) {
                    this.setState({
                        timer_countdown: this.state.timer_countdown - 1
                    });
                } else {
                    this.setState({timer_countdown: this.props.count_down})
                    this.props.onUpdate();
                }
            }, 1000);
            //this.props.onUpdate();
    };

    public componentWillUnmount(): void
    {
        if(this.timer)
            clearInterval(this.timer);
    }


    render = () => (
        <p className={this.props.className}>{this.props.text} {this.state.timer_countdown}sec <img src="img/refresh.svg" alt="loader" className="loader" /></p>
    );
}