import * as React from "react";

export const AboutInfoContent = () => {
    return (
        <div className="container-fluid light">
            <div className="row">
                <div className="col-lg-12">
                    <div className="main-about__content text-center">
                        <h2 className="title title__about">About Us</h2>
                        <div className="main-about__picture">
                            <img src="img/about.png" alt="about_pic" className="img-fluid" />
                        </div>
                        <div className="main-about__text_wrap">
                            <p className="main-about__text">Wisenex has always been following the trends, that is why an
                                Auto Exchange function has been introduced. Before there were few companies, that could
                                offer it. This feature helps to create a template carrying out operations without
                                entering the platform. </p>
                            <p className="main-about__text">We moved forward and implemented an API interface, giving an
                                opportunity to carry out operations on the platform. </p>
                            <p className="main-about__text">Each client is important, so we offer learning programs on
                                good conditions and an opportunity to make money on your own. Launching the platform we
                                introduced the Affiliate Program. It allows affiliates to receive up to 20% interest for
                                each exchange transaction.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}