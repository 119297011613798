import {Component, default as React} from "react";
import {Header} from "../components/Header";
import {toast, ToastContainer} from "react-toastify";
import {Footer} from "../components/Footer";
import {RecoveryContent} from "../components/recovery/RecoveryContent";
import {ApiData} from "../models/ApiData";

export class PasswordRecoveryPage extends Component {
    state = {
        email: ""
    }

    onSubmit = async (data: any) => {

        if(data.email) {
            const result = await ApiData.clientApiRequest({email: data.email}, "reset_password_email");
            if(result.success)
                this.setState({email: data.email});
            else
                toast.error(result.message);
        }
     }


    render = () => (
        <>
            <Header isDark={false} />
            <ToastContainer autoClose={6000} />

            <RecoveryContent onSubmit={this.onSubmit} email={this.state.email}  />

            <Footer isDark={false} />
        </>
    );
}