import {Component, default as React} from "react";
import {Header} from "../components/Header";
import {ToastContainer} from "react-toastify";
import {RatesDiffContent} from "../components/rates/RatesDiffContent";
import {RatesCommissionContent} from "../components/rates/RatesCommissionContent";
import {Footer} from "../components/Footer";
import {ContactContent} from "../components/contact/ContactContent";

export class ContactPage extends Component {
    render = () => (
        <>
            <Header isDark={false}/>
            <ToastContainer autoClose={6000}/>
            <ContactContent />
            <Footer isDark={false}/>
        </>
    );
}