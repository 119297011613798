import {strict} from "assert";
import {publicDecrypt} from "crypto";
import {DefaultValues} from "./DefaultValues";

export interface IHttpResponse<T> extends Response {
    parsedBody?: T;
}

export enum ExceptionCodes {

    VERIFICATION_UNKNOWN_CODE = 4000,
    VERIFICATION_EMAIL_CODE = 4001,
    VERIFICATION_PHONE_CODE = 4002,

     NO_AUTHORIZATION_CODE = 5000,
     NO_AUTHORIZATION_NOT_ALLOWED_IP_CODE = 5001,

    CAPTCHA_CODE = 6000,

    INVALID_DATA = 7000,
    INVALID_WALLET = 7001
}

export const http = <T>(request: RequestInfo): Promise<IHttpResponse<T>> => {
    return new Promise((resolve, reject) => {
        let response: IHttpResponse<T>;
        fetch(request)
            .then(res => {
                response = res;
                return res.json();
            })
            .then(body => {
                if (response.ok) {
                    response.parsedBody = body;
                    resolve(response);
                } else {
                    reject(response);
                }
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const getApiDataPromise = async (body: any, method: string, action: string): Promise<any> => {
    try {
        body.action = action;
        let response = await fetch(
            DefaultValues.PAY + 'api/' + method + '.htm',
            {
                method: 'post',
                headers:new Headers({
                    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
                }),
                body: jQuery.param(body),
                credentials: "include"
            });

        return response.json();
    } catch (response) {
        console.log("Error", response);
    }
    return false;
};


class ApiResponse {
    success: boolean;
    data: any;
    message: string;
    code: number;
}

export abstract class ApiData {

    public static async clientApiRequest(body: any, action: string): Promise<ApiResponse>
    {
        return await getApiDataPromise(body,"client", action);
    }

    public static async exchangeApiRequest(body: any, action: string): Promise<ApiResponse>
    {
        return await getApiDataPromise(body,"exchange", action);
    }

    public static async publicApiRequest(body: any, action: string): Promise<ApiResponse>
    {
        return await getApiDataPromise(body,"public_data", action);
    }

    public static async getSelectorList(name: string, param: string = null): Promise<any[]> {
        var key = name + "_kv_list";
        var key_cahce = key + "_5";

        if(param == null && name != "fiat") {
            var cached_data = localStorage.getItem(key_cahce);

            if (cached_data)
                return JSON.parse(cached_data);
        }

        var result = await getApiDataPromise({param: param},"public_data", "get_" + key);

        if(result.success) {
            localStorage.setItem(key_cahce, JSON.stringify(result.data));
            return result.data;
        }

        return [];
    }

    public static async getCurrenciesExchange(): Promise<any[any]> {
        var result = await getApiDataPromise({},"exchange", "get_currencies_exchange");
        return result.success ? result.data : [];
    }
}
