export abstract class UrlUtils {

    public static getUrlParamsAsArray()
    {
        var vars = {}, hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars[hash[0]] = hash[1];
        }
        return vars;
    }

    public static getUrlParam(key: string)
    {
        const query = new URLSearchParams(window.location.search);
        return query.get(key);
    }

    public static redirectTo(url: string)
    {
        window.location.href = url;
    }

    public static redirectToPOST(url:string, params:any)
    {
        var html = '<form method="post" action="' + url + '">';

        params.forEach(function(value:string, key:string) {
            html += '<input type="hidden" name="' + key + '" value="' + value + '">';
        });

        html += '</form>'

        var form = $(html);

        form.hide().appendTo('body');
        form.submit();
    }

    public static getRedirectForm(url:string, params:any)
    {
        var form = '';
        $.each( params, function( key: any, value: any ) {
            form += '<input type="hidden" name="'+key+'" value="'+value+'">';
        });
        return '<form action="'+url+'" method="POST">'+form+'</form>';
    }
}
