import * as React from "react";
import {Footer} from "../components/Footer";
import {HeaderSimple} from "../components/HeaderSimple";
import {ExchangeStep1DetailsContent} from "../components/exchange/ExchangeStep1DetailsContent";
import {ExchangeStep2PersonalContent} from "../components/exchange/ExchangeStep2PersonalContent";


import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {ExchangeStep3DocContent} from "../components/exchange/ExchangeStep3DocContent";
import {NotFoundPage} from "./NotFoundPage";
import {ACTIONS, ExchangeSession} from "../models/ExchangeSession";
import {ExchangeStep4RedirectContent} from "../components/exchange/ExchangeStep4RedirectContent";
import {ExchangeStep5StatusContent} from "../components/exchange/ExchangeStep5StatusContent";
import {ExchangeStep42PaymentInfoContent} from "../components/exchange/ExchangeStep42PaymentInfoContent";

export class ExchangePage extends React.Component {
    state = {
        step: 1
    }

    public UNSAFE_componentWillMount (): void {
        const query = new URLSearchParams(window.location.search);

        this.setState( {
            step: parseFloat(query.get('step'))
        });
    }


    render = () => <>
        <HeaderSimple isDark={false}/>
        <ToastContainer autoClose={6000}/>

        {(() => {
            switch (this.state.step) {
                case 1:
                    return <ExchangeStep1DetailsContent/>;
                case 2:
                    return <ExchangeStep2PersonalContent/>;
                case 3:
                    return <ExchangeStep3DocContent/>;
                case 4:
                    return <ExchangeStep4RedirectContent/>;
                case 4.2:
                    return <ExchangeStep42PaymentInfoContent/>;
                case 5:
                    return <ExchangeStep5StatusContent/>;
                default:
                    ExchangeSession.saveDataParam("step", "1");
                    return <ExchangeStep1DetailsContent/>;
            }
        })()}

        <Footer isDark={false}/>
    </>;
}

