import {NetUtils} from "../utils/NetUtils";
import {ApiData} from "./ApiData";
import {ExClient} from "./ExClient";
import {ExchangeSession} from "./ExchangeSession";
import {UrlUtils} from "../utils/UrlUtils";

export class Exchange {
    public static async getTransactionAndUpdateByPaymentToken(payment_token: string, is_status_page: boolean): Promise<any>
    {
        let result = await ApiData.exchangeApiRequest({payment_token: payment_token, is_status_page: is_status_page}, 'get_transaction_and_update');

        if(result && result.code == 1) {
            ExchangeSession.saveDataParam("crypto_input",  result.data.crypto_amount);
            return result.data;
        }
        return false;
    }

    public static async getRateAndCommission(crypto_currency: string, fiat_currency: string, shop_id: any): Promise<any>
    {
        let requestData = {
            crypto_symbol: crypto_currency,
            fiat_symbol: fiat_currency,
            shop_id: shop_id
        };

        let result = await ApiData.exchangeApiRequest(requestData, 'get_rate_and_commission_crypto_symbol');

        if(result && result.code == 1)
            return result.data;
        return false;
    }

    public static async create(isAgain = false): Promise<any>
    {
        const session_data = ExchangeSession.data;
        const result = await ApiData.exchangeApiRequest(
            {
                fiat_amount: session_data['fiat_input'],
                fiat_currency: session_data['fiat_select'],
                crypto_amount: session_data['crypto_input'],
                crypto_currency: session_data['crypto_select'],
                payment_system_id: ExClient.payment_id,
                wallet: session_data['wallet'],
                again: isAgain ? 1 : 0
            },
            "create"
        );

        if (result.success) {
            if(result.data.payment_system_id)
                ExClient.payment_id = result.data.payment_system_id;

            session_data['payment_token'] = result.data.token;
            session_data['ex_transaction_id'] = result.data.ex_transaction_id;

            session_data['self_processing'] = result.data.self_processing;
            session_data['crypto_input'] = result.data.crypto_amount;
            ExchangeSession.data = session_data;
        }

        return result;
    }
}