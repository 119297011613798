import * as React from "react";
import {ExchangeSession} from "../../models/ExchangeSession";
import {UrlUtils} from "../../utils/UrlUtils";

export const ButtonCancel = () =>
{
    return <button type="button" onClick={(e) =>
    {
        ExchangeSession.cancel();
        UrlUtils.redirectTo("/");
    }} className={"btn btn-link steps__btn-link"}>Cancel</button>
}