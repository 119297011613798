import * as React from "react";
import {LoginProps} from "./LoginContent";

export interface BlockProps {
    visible: boolean;
    text: string;
}

export const VerifiedBlock = ({visible = false, text = "verified!"}: BlockProps) =>
    visible && <div className="row row_verified">
                    <div className="col-lg-12">
                        <div className="d-flex align-items-center steps__inf text-success">
                            <img className="steps__inf_checing" src="img/available.svg" alt="check" />
                            <span className="steps__inf_text text-success">{text}</span>
                        </div>
                    </div>
                </div>