import * as React from "react";
import {Component} from "react";

export interface DiffBlockProps {
    symbol: string;
    rate: number;
    change: number;
    rate_symbol: string;
    onSelect: (symbol: string) => void
}

export class DiffBlock  extends Component<DiffBlockProps, {}>  {

    onSelect = (e:React.MouseEvent<HTMLButtonElement>) =>
    {
        e.preventDefault();
        const symbol = e.currentTarget.value;
        this.props.onSelect(symbol);
    }

    render = () => {
        const img = this.props.symbol ? this.props.symbol.toLowerCase() : '';
        const arrow = this.props.change >= 0 ? 'arrowup' : 'arrowdown';
        const text_color = this.props.change >= 0 ? 'success' : 'danger';

        return (
            <div className="col-lg-6">
                <div className="rates__item box-shadow d-flex align-items-center justify-content-between">
                    <div className="rates__part d-flex align-items-center">
                        <img src={"img/rates/currency/" + img + ".svg"} alt="img" className="rates__ic"/>
                        <span className="rates__name">1 {this.props.symbol} = </span>
                        <span className="rates__summ">{this.props.rate_symbol == "USD" ? "$" : "€"} {this.props.rate}</span>
                    </div>
                    <div className="rates__part d-flex align-items-center">
                        <span className={"rates__value text-" + text_color}> <img
                            src={"img/rates/icons/" + arrow + ".svg"} alt={arrow}/> {this.props.change}%</span>
                        <button value={this.props.symbol} onClick={this.onSelect} className="rates__currency_item rates__bye">Buy</button>
                    </div>
                </div>
            </div>
        );
    }

}