import * as React from "react";

export const Promo = () =>
    <div className="steps__part_small">
    <div className="steps__box_dark">
        <div className="steps__box_top">
            <h3 className="steps__box_title">Buy with confidence</h3>
        </div>
        <div className="steps__item_wr">
            <div className="steps__item">
                <img src="img/01.svg" alt="icon" className="steps__item_ic" />
                <div className="steps__item_text">
                    <h3 className="steps__item_title">SECURITY</h3>
                    <p className="steps__item_des">The highest standards of encrypting
                        are employed to protect your data.</p>
                </div>
            </div>
            <div className="steps__item">
                <img src="img/03.svg" alt="icon" className="steps__item_ic" />
                <div className="steps__item_text">
                    <h3 className="steps__item_title">NO COMMISSION</h3>
                    <p className="steps__item_des">Every day you can chose the operation
                        you need with 0 commission</p>
                </div>
            </div>
            <div className="steps__item">
                <img src="img/02.svg" alt="icon" className="steps__item_ic" />
                <div className="steps__item_text">
                    <h3 className="steps__item_title">24 HOUR CLIENT SERVICE</h3>
                    <p className="steps__item_des">We never sleep!</p>
                </div>
            </div>
        </div>
    </div>
</div>
