import {Component, default as React} from "react";
import {NetUtils} from "../../utils/NetUtils";

export class AvailableCountriesList extends Component {

    state =
        {
            countries: new Array(),
            filter_region: 0
        };
    all_countries = new Array();

    async componentDidMount(): Promise<void> {
        this.all_countries = await NetUtils.getApiDataPromiseResultWithRetry({}, "public_data", "get_country_list");
        this.setState(
            {
                countries: this.all_countries
            }
        )
    }

    filterCountries = (region: number) =>
    {
        this.setState(
            {
                countries: region == 0 ? this.all_countries : this.all_countries.filter((e) => e.region_id == region),
                filter_region: region
            }
        )
    }

    render = () => (
        <div className="countries">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="countries__wrapper">
                            <ul className="nav nav-pills countries__pills justify-content-lg-center" role="tablist">
                                <li className="nav-item">
                                    <a onClick={() => this.filterCountries(0)} className="nav-link active" id="pills-All-tab" data-toggle="pill"
                                       href="#pills-All" role="tab" aria-controls="pills-All"
                                       aria-selected="true">All</a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={() => this.filterCountries(1)} className="nav-link" id="pills-Africa-tab" data-toggle="pill"
                                       href="#pills-Africa" role="tab" aria-controls="pills-Africa"
                                       aria-selected="false">Africa</a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={() => this.filterCountries(2)}  className="nav-link" id="pills-Asia-tab" data-toggle="pill" href="#pills-Asia"
                                       role="tab" aria-controls="pills-Asia" aria-selected="false">Asia</a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={() => this.filterCountries(3)} className="nav-link" id="pills-Australia-tab" data-toggle="pill"
                                       href="#pills-Australia" role="tab" aria-controls="pills-Australia"
                                       aria-selected="false">Australia</a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={() => this.filterCountries(4)} className="nav-link" id="pills-Europe-tab" data-toggle="pill"
                                       href="#pills-Europe" role="tab" aria-controls="pills-Europe"
                                       aria-selected="false">Europe</a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={() => this.filterCountries(5)}  className="nav-link" id="pills-North_America-tab" data-toggle="pill"
                                       href="#pills-North_America" role="tab" aria-controls="pills-North_America"
                                       aria-selected="false">North America</a>
                                </li>
                                <li className="nav-item">
                                    <a onClick={() => this.filterCountries(6)} className="nav-link" id="pills-South_America-tab" data-toggle="pill"
                                       href="#pills-South_America" role="tab" aria-controls="pills-South_America"
                                       aria-selected="false">South America</a>
                                </li>
                            </ul>
                            <div className="tab-content countries__tab-content">
                                <div className="tab-pane fade show active" id="pills-All" role="tabpanel"
                                     aria-labelledby="pills-All-tab">
                                    <div className="table-responsive">
                                        <table className="table table__countries table-borderless table-striped">
                                            <tbody>
                                                <tr>
                                                    <th>COUNTRY</th>
                                                    <th>BUY</th>
                                                    <th>SELL</th>
                                                    <th>PAYMENT METHODS</th>
                                                    <th>DEBIT CARD ISSUING</th>
                                                </tr>


                                                    {this.state.countries.map((e: any, index) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <img src={"img/countries/" + e.region_name.toLowerCase().replace(new RegExp(' ', 'g'), '_') + "/" + e.name.toLowerCase().replace(new RegExp(' ', 'g'), '-') + ".svg"}
                                                                     alt="countries" className="countries__ic"/>
                                                                <span className="countries__name"> {e.name} </span>
                                                            </td>

                                                            {e.is_enabled == "1" ?
                                                                <>
                                                                    <td>
                                                                        <img src="img/lavachange_coutries/icons/check.svg" alt="close" />
                                                                    </td>
                                                                    <td>
                                                                        <img src="img/lavachange_coutries/icons/check.svg" alt="close" />
                                                                    </td>
                                                                    <td>
                                                                        Credit Card, Bank Transfer
                                                                    </td>
                                                                    <td>
                                                                        Coming Soon!
                                                                    </td>
                                                                </>
                                                                :
                                                                <>
                                                                    <td>
                                                                        <img src="img/lavachange_coutries/icons/close.svg" alt="close" />
                                                                    </td>
                                                                    <td>
                                                                        <img src="img/lavachange_coutries/icons/close.svg" alt="close" />
                                                                    </td>
                                                                    <td>

                                                                    </td>
                                                                    <td>
                                                                        <img src="img/lavachange_coutries/icons/close.svg" alt="close" />
                                                                    </td>
                                                                </>}
                                                        </tr>
                                                    )}

                                            </tbody>
                                        </table>
                                    </div>
                                    <button className="button button-big button_bg_red countries__more">Show more countries</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}