import * as React from "react";
import {Header} from "../components/Header";
import {Footer} from "../components/Footer";

import {toast, ToastContainer} from "react-toastify";
import {UrlUtils} from "../utils/UrlUtils";
import {ResultInfo} from "../components/exchange/ResultInfo";
import {Promo} from "../components/login/Promo";
import {getApiDataPromise} from "../models/ApiData";
import {isNumber, types} from "util";


export class InfoPage extends React.Component {

    state = {
        code : 0,
        message: ""
    }

    public componentDidMount(): void {
        this.getMessage();
    }

    private async getMessage()
    {
        let code = Number(UrlUtils.getUrlParam('code'));
        if(!isNaN(code))
        {
            let result = await getApiDataPromise({code: code},"public_data", "get_error_message_by_code");
            console.log(result);

            if(result.success)
                this.setState({code: code, message: result.data})
            else
                this.setState({code: code})
        }
        else UrlUtils.redirectTo("/");
    }

    render = () => <>
        <Header isDark={false}/>
        <ToastContainer autoClose={6000}/>

        <>
            <div className="body__steps body__steps_small">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="steps__box">
                                <div className="row no-gutters">
                                    <div className="steps__part_big steps__part_big_bg">
                                        <div className="steps__box_light info_message">
                                            {this.state.code > 0 && <ResultInfo message_head={"Error code: " + this.state.code}  message={this.state.message} success={false}/>}
                                        </div>
                                    </div>

                                    <Promo/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

        <Footer isDark={false}/>
    </>;
}

