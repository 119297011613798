import React from 'react'
import useForm from 'react-hook-form'
import {ExceptionCodes, getApiDataPromise} from "../../models/ApiData";
import {ExClient} from "../../models/ExClient";
import {toast} from "react-toastify";

export default function LoginForm() {

    const { register, handleSubmit, watch, errors } = useForm();

    const onSubmit = async function (data: any) {
        console.log(data);
        var result = await getApiDataPromise(data,"client", "login");

        if(result.success)
            ExClient.redirectLogin();
        else {
            toast.error(result.message);

            if(result.code == ExceptionCodes.VERIFICATION_EMAIL_CODE)
            {
                // @ts-ignore
                $("#email_sent").modal('toggle');
            }
            else if(result.code == ExceptionCodes.NO_AUTHORIZATION_NOT_ALLOWED_IP_CODE)
            {
                // @ts-ignore
                $("#ip_verify").modal('toggle');
            }
        }
    }

    return (
     <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="steps__form">
                <div className="steps__form_boxing">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form__item">
                                <input name="email" type="text" className="input input_small"  placeholder="Email"  id="email" ref={register({ required: true, pattern: /^\S+@\S+$/i })}  />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form__item_modal">
                                <input name="password" type="password" className="input input_small"  placeholder="Password" ref={register({ required: true })}/>
                                {errors.passwordRequired && <span>This field is required</span>}

                                <img src="img/see.svg" alt="see" className="in_password" />
                                <a href="recovery" className="form__item_link">Forgot your password?</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="steps__form_footer">
                            <button type="submit"
                                    className="button button_bg_red steps-form__submit">Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
     </>
    )
}