import * as React from "react";
import {EmailVerifyModal} from "../login/EmailVerifyModal";
import OptionsApiData from "../common/OptionsApiData";

export interface StepsProps {
    step_id: number;
    steps_list?: string[]
}

export const ExchangeStepsLine = (
    {
        step_id = 0,
        steps_list =
    [
        "TRANSACTION DETAILS",
        "Personal Info",
        "Document Verification",
        "Payment",
        "Status Check",
    ]}: StepsProps) =>
    <>
        <ul className="steps__line list">
            {steps_list.map((e: string, index:number) =>  <li key={index} className={"steps__line_item" + (index <= step_id ? " active" : "")}>{e}</li>)}
        </ul>
    </>;