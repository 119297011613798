import { default as React } from 'react'

export const ExchangeStep3Message = () => {
  return (
    <div className="notifications">
      <div className="box cryptocurrency-faq__box text-center">
        <p><img src={'img/available.svg'} alt="icons" className="benefits__ic"/></p>
        <p className="cryptocurrency-faq__text dark">Your documents have been successfully uploaded.
          Manager will contact you soon for account verification purposes.</p>
      </div>
    </div>
  )
}
