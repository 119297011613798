import * as React from "react";
import {Header} from "../components/Header";
import {LoginContent} from "../components/login/LoginContent";
import {Footer} from "../components/Footer";
import {ExClient} from "../models/ExClient";
import {toast, ToastContainer} from "react-toastify";
import {UrlUtils} from "../utils/UrlUtils";

export interface ILoginPageState {
    showEmailVerifyInfo: boolean;
    showIPVerifyInfo: boolean;
}


export class LoginPage extends React.Component<{}, ILoginPageState> {
    constructor(props: ILoginPageState) {
        super(props);
        this.state = {
            showEmailVerifyInfo: false,
            showIPVerifyInfo: false,
        }
    }
    public componentDidMount(): void {
        this.checkLogin();
        this.checkUrl();
    }

    private async checkLogin()
    {
        const result = await ExClient.checkLogin();
        console.log("CHECK LOGIN", result);
        if(result) {
            ExClient.redirectLogin();
        }
    }


    private checkUrl()
    {
        console.log("checkUrl");
        if(window.location.hash == "#success_confirm_email") {
            this.setState({
                showEmailVerifyInfo: true
            });
        }
        else if(window.location.hash == "#success_confirm_ip")
        {
            this.setState({
                showIPVerifyInfo: true
            });
        }
        else if(window.location.hash == "#registered")
        {
            // @ts-ignore
            $("#email_sent").modal('toggle');
        }
        if(window.location.hash == "#exists")
            toast.info("This email already registered");


        let email = UrlUtils.getUrlParam('email');
        if(email)
            $("#email").val(email);
    }

    render = () => <>
        <Header isDark={false}/>
        <ToastContainer autoClose={6000}/>

        <LoginContent showEmailVerifyBlock={this.state.showEmailVerifyInfo} showIPVerifyInfo={this.state.showIPVerifyInfo}/>
        <Footer isDark={false}/>
    </>;
}

