import * as React from "react";
import {HeaderSimple} from "../components/HeaderSimple";
import {Footer} from "../components/Footer";

export const NotFoundPage = () =>
    <>
        <HeaderSimple isDark={false}/>
        <div className="container">
            <div className="not-found">
                <br/>
                <h1 className="title">
                    Not Found!
                </h1>
                <h1 className="title title-big">
                    404
                </h1>
                <br/>
            </div>
        </div>
        <Footer isDark={false}/>
    </>
