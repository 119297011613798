import {DefaultValues} from "../models/DefaultValues";

export abstract class NetUtils {

    static getApiDataPromiseResultWithRetry = async (body: any, method: string, action: string, stage: number = 0): Promise<any> => {
        try {
            body.action = action;
            let response = await fetch(
                DefaultValues.PAY + 'api/' + method + '.htm',
                {
                    method: 'post',
                    headers:new Headers({
                        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    }),
                    body: jQuery.param(body),
                    credentials: "include"
                });

            const response_json = await response.json();

            if(response_json['success'])
                return response_json['data'];

        } catch(e) {
            console.log("Error", e);
            if(stage == 5)
                return false;
            await NetUtils.delay(200);
            return await NetUtils.getApiDataPromiseResultWithRetry(body, method, action, stage + 1);
        }

        return false;
    };

    static delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
