import {Component, default as React} from "react";
import {Header} from "../components/Header";
import {toast, ToastContainer} from "react-toastify";
import {RecoveryContent} from "../components/recovery/RecoveryContent";
import {Footer} from "../components/Footer";
import {UrlUtils} from "../utils/UrlUtils";
import {ApiData} from "../models/ApiData";

export class PasswordSetPage extends Component {
    state = {
        token:  ""
    }

    componentDidMount(): void {
        const token = UrlUtils.getUrlParam("token");
        if(token)
            this.setState({token: token});
        else
            UrlUtils.redirectTo("/");
    }


    onSubmit = async (data: any) => {
        const result = await ApiData.clientApiRequest({token: this.state.token, password: data.password}, "reset_password");
        if(result.success)
            UrlUtils.redirectTo("login");
        else
            toast.error(result.message);
    }

    render = () => (
        <>
            <Header isDark={false} />
            <ToastContainer autoClose={6000} />

            <RecoveryContent onSubmit={this.onSubmit} email="" token={this.state.token} />

            <Footer isDark={false} />
        </>
    );
}