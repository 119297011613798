import * as React from "react";

export const PromoContacts = () => {
    return (
        <div className="steps__part_small">
            <div className="steps__box_dark">
                <div className="steps__box_top">
                    <h3 className="steps__box_title"> Contact us</h3>
                </div>
                <div className="steps__item_wr">
                    <div className="footer__item footer__item_info">
                        <img src="img/footer-w/flag/01.svg" alt="flag" className="footer__flag" />
                            <span className="footer__text footer__tel">+3726 980-426</span>
                            <span className="footer__text footer__tel">Mon - Fri: GMT 8AM - 8PM</span>
                    </div>
                    <div className="footer__item footer__item_info">
                        <img src="img/footer-w/flag/02.svg" alt="flag" className="footer__flag"/>
                        <span className="footer__text footer__tel">+44 2030 973 - 956</span>
                        <span className="footer__text footer__tel">Mon - Fri: GMT 3AM - 3PM</span>
                    </div>
                    <div className="footer__item footer__item_info">
                        <img src="img/netherlands.svg" alt="flag" className="footer__flag"/>
                        <span className="footer__text footer__tel">+31 2026 219 36</span>
                        <span className="footer__text footer__tel">Mon - Fri: GMT 3AM - 3PM</span>
                    </div>
                    <div className="footer__item footer__item_info">
                        <span className="contanct__form_text">Business inquiries email:</span>
                        <span className="contanct__form_em">finance@wisenex.com</span>
                    </div>
                    <div className="footer__item footer__item_info">
                        <span className="contanct__form_text">Support email:</span>
                        <span className="contanct__form_em">support@wisenex.com</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
