import * as React from "react";

export default function RecoverySentText(email: string) {
    return (
        <div className="steps__form">
            <div className="steps__form_boxing">
                <div className="row">
                    <div className="col-lg-12">
                        <p>We’ve sent an email to <span className="text-danger">{email}</span> with the
                            reset password link</p>
                        <p>Please check your email and follow instructions</p>
                        <p> Remembered password? <a href="/login">Login</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}