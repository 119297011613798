import * as React from "react";

export interface HeaderProps {
    isDark?: boolean;
    onlyLogo?: boolean
}

export const HeaderSimple = ({onlyLogo=false,isDark = true}: HeaderProps) => {
    let theme = isDark ? {
            navbarClass: "navbar navbar-expand-lg light",
            logo: "white",
        } :
        {
            navbarClass: "navbar navbar-expand-lg dark",
            logo: "black",
        };

    return (
        <header className="header steps__header">
            <div className="container">
                <div className={`row align-items-center${onlyLogo?" justify-content-center":""}`}>
                    <div className="col-lg-2">
                        <a className="steps__logo" href="/">
                            <img src={'img/logo_' + theme.logo + '.svg'} alt="logo" className="steps__logo_img" />
                        </a>
                    </div>
                    {!onlyLogo && (
                    <div className="col-lg-10">
                        <span className="steps__slogan d-block">Buy BTC with a credit card<br /> no deposit is required</span>
                    </div>
                    )}
                </div>
            </div>
        </header>
    );
};
