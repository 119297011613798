import {Component, createRef, default as React} from "react";
import {ExchangeStepsLine} from "./ExchangeStepsLine";
import ExchangeSummaryDetails from "./ExchangeSummaryDetails";
import {ExchangeSession} from "../../models/ExchangeSession";
import {ApiData} from "../../models/ApiData";
import {useHistory} from "react-router";
import {UrlUtils} from "../../utils/UrlUtils";
import {toast} from "react-toastify";
import {ExClient} from "../../models/ExClient";
import {DefaultValues} from "../../models/DefaultValues";

export class ExchangeStep42PaymentInfoContent extends Component {
    state = {
        fiatCurrency: "EUR",
        cryptoCurrency: "BTC",
        wallet: '',
        fiat_amount: 0,
        crypto_amount: 0,
        error_msg: false,
        form: {},
        ex_transaction_id: false,
        payment_token: false
    }
    exchange_summary: React.RefObject<ExchangeSummaryDetails> = createRef();


    public componentDidMount(): void {

        ExchangeSession.redirectIfWrongStep("4.2");

        let session_data = ExchangeSession.data;
        let state = ExchangeSession.getExchangeState();

        if(session_data && session_data['payment_token']) {
            this.setState(state);
        }
        else
        {
            ExchangeSession.data = null;
            const history = useHistory();
            UrlUtils.redirectTo('exchange');
        }

        window.addEventListener("message", this.onSecureMessage, false);
    }

    onSecureMessage = (event: any) => {

        console.log("OK", event.data, event.origin)

        let origin = event.origin + "/";
        if (origin !== DefaultValues.SECURE && origin !== DefaultValues.PAY)
            return;

        let result = event.data;
        //event.source.postMessage("Hi", event.origin);

        if (result.success) {
            if (result.type == "form") {
                console.log("Set form ", event.data)

                if(result.data.type == "GET")
                {
                    ExchangeSession.saveDataParam('step', "5");
                    UrlUtils.redirectTo(result.data.url);
                } else {
                    this.setState(
                        {
                            form: result.data
                        }, () => {
                            ExchangeSession.saveDataParam('step', "5");
                            $("#redir").submit()
                        }
                    )
                }
            } else if (result.type == "simple") {
                if (result.success) {
                    ExchangeSession.saveDataParam('step', "5");
                    UrlUtils.redirectTo("exchange?step=5");
                } else toast.error(result.message);

            } else if (result.type == "frame") {
                // securetradingjs only TODO: do it in right way
                // eslint-disable-next-line no-restricted-globals
                frames["payFrame"].location = result.data.url + "?jwt=" + result.data.params[0].value + "&redirect=" + encodeURIComponent(result.data.params[1].value) + "&demo=" + result.data.params[2].value;
            } else if (result.type == "error")
                toast.error(result.data.message);


        }
        else
            toast.error("Error while processing.");
    }

    render = () => {
        let redirect;
        if(this.state.form['url']) {
            let params = this.state.form['params'];
            if(params)
                redirect = <form id="redir"  action={this.state.form['url']} method="POST">{params.map((d: any) => <input type="hidden" name={d.key} value={d.value} ></input>)}</form>;
            else
                redirect = <form id="redir" action={this.state.form['url']} method="GET"></form>;
        }

        const iFrameSrc = DefaultValues.SECURE
          + "?token=" + this.state.payment_token
          + "&p_id=" + ExClient.payment_id
          + '&name=' + (ExClient.payment_name === 'maestro' ? 'mastercard' : ExClient.payment_name)

        return (
            <div className="body__steps">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 steps__dots_bg">
                            <ExchangeStepsLine step_id={3}/>
                        </div>
                        <div className="col-lg-10">
                            <div className="steps__box steps__box_boxing">
                                <div className="row no-gutters">
                                    <div className="steps__part_big">
                                        <div className="iframe__form">
                                            {this.state.payment_token &&
                                            <iframe name="payFrame" width="100%" height="610px" src={iFrameSrc}
                                                    frameBorder="0"></iframe>}
                                        </div>
                                        {redirect}
                                    </div>
                                    <div className="steps__part_small">
                                        <div className="steps__box_dark">
                                            <ExchangeSummaryDetails ex_transaction_id={this.state.ex_transaction_id}
                                                                    payment_token={this.state.payment_token}
                                                                    wallet={this.state.wallet}
                                                                    ref={this.exchange_summary}
                                                                    fiat_amount={this.state.fiat_amount}
                                                                    crypto_currency={this.state.cryptoCurrency}
                                                                    fiat_currency={this.state.fiatCurrency}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
