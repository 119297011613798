import {Component, default as React} from "react";

export class WarningInfo extends Component {
    render = () => (
        <div className="row">
            <div className="col-lg-12">
                <div className="exchange__avialibe no-avialibe d-flex notificy_steps">
                    <span className="exchange__avialibe_text">
                        {this.props.children}
                    </span>
                </div>
            </div>
        </div>
    );
}