import * as React from "react";
import {Promo} from "../login/Promo";
import SignUpForm from "./SignUpForm";
import {Link} from "react-router-dom";

export const SignUpContent = () =>
    <div className="body__steps register">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="steps__box">
                        <div className="row no-gutters">
                            <div className="steps__part_big steps__part_big_bg">
                                <div className="steps__box_light">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3 className="steps__box_title">Register </h3>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="steps__box_title text-lg-right text-left">
                                                <span className="title__light">Already registered? </span>
                                                <a href="login" className="title__link">Login</a>
                                            </h3>
                                        </div>
                                    </div>

                                    {SignUpForm()}

                                </div>
                            </div>


                            <Promo/>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>