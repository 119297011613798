
export abstract class StringUtils {
    public static isEmail = (email:string) => {
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return expression.test(String(email).toLowerCase())
    }

    static isBtcWallet(wallet: string) {
        //const expression = /(^[13][a-km-zA-HJ-NP-Z1-9]{25,34})$/i;
        const cryptaddress = require('cryptaddress-validator');
        return cryptaddress('btc').test(wallet);
    }

    static isUsdtTrc20Wallet(wallet: string) {
        const expression = /(^T[0-9a-zA-Z]{25,34})$/i;
        let regexp = new RegExp(expression);

        return regexp.test(wallet);
    }

    static isValidWallet(wallet: string, type: string) {
        //const expression = /(^[13][a-km-zA-HJ-NP-Z1-9]{25,34})$/i;
        const cryptaddress = require('cryptaddress-validator');
        const typeLower = type.toLowerCase();

        if (typeLower === "usdt-trc20")
            return this.isUsdtTrc20Wallet(wallet);

        return cryptaddress(typeLower).test(wallet);
    }

    static isEmpty(s: string,) {
        return !s || s.length == 0;
    }


    static capitalizeFirstLetterAndRemoveQuotes(s: string) : string {
        return StringUtils.capitalizeFirstLetter(s.replace("'", ""));
    }

    static capitalizeFirstLetter(s: string) : string {
        if(s.length > 0)
            return s.charAt(0).toUpperCase() + s.slice(1)
        return s;
    }
}

