import {Component, default as React} from "react";
import {StringUtils} from "../../utils/StringUtils";


export interface IErrorBox {
    message_head: string;
    message: any,
    success: boolean,
    id?:any
}

export class ResultInfo extends Component<IErrorBox, {}> {

    public render() {
        return (
            <div className="notifications">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div
                                className={"box cryptocurrency-faq__box text-center " + this.props.success ? "text-center" : "text-danger"}>
                                <img src={"img/" + (this.props.success ? "available.svg" : "unavailable.svg")} alt="icons" className="benefits__ic"/>
                                <h3 className="cryptocurrency-faq__title">{this.props.id ? "#" + this.props.id + ": " + this.props.message_head : this.props.message_head}</h3>
                                <p className="cryptocurrency-faq__text dark">{!this.props.success && StringUtils.capitalizeFirstLetterAndRemoveQuotes(this.props.message)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}