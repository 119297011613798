import * as React from "react";
import { Switch, Route, BrowserRouter, Redirect} from "react-router-dom";
import {IndexPage} from "./pages/IndexPage";
import {LoginPage} from "./pages/LoginPage";
import {NotFoundPage} from "./pages/NotFoundPage";
import { SignUpPage } from "./pages/SignUpPage";
import {ExchangePage} from "./pages/ExchangePage";
import {ExClient} from "./models/ExClient";
import {RatesPage} from "./pages/RatesPage";
import {PasswordRecoveryPage} from "./pages/PasswordRecoveryPage";
import {PasswordSetPage} from "./pages/PasswordSetPage";
import {DefaultValues, initVars} from "./models/DefaultValues";
import {CardsPage} from "./pages/CardsPage";
import {AvailablePage} from "./pages/AvailablePage";
import {AffiliatePage} from "./pages/AffiliatePage";
import {AboutPage} from "./pages/AboutPage";
import {ContactPage} from "./pages/ContactPage";
import {InfoPage} from "./pages/InfoPage";
// import {TariffsPage} from "./pages/TariffsPage";
import {AvoidScamsPage} from "./pages/AvoidScamsPage";
import { NoLongerPage } from "./pages/NoLonger";

class App extends React.Component {
    constructor(props: any) {
        super(props);
        initVars(process.env.NODE_ENV !== 'production' || process.env["REACT_APP_BUILD_TYPE"] === "dev");
        ExClient.init();
    }

    componentDidMount(): void {
        console.log(DefaultValues, window.location.href);
    }

    render = () => <>
            <BrowserRouter>
                <Switch>
                    <Route exact={true} path="/" component={NoLongerPage} />
                    <Route path="*" render={() => <Redirect to="/" />} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/sign_up" component={SignUpPage} />
                    <Route path="/exchange" component={ExchangePage} />

                    <Route path="/rates" component={RatesPage} />
                    <Route path="/cards" component={CardsPage} />
                    <Route path="/affiliate" component={AffiliatePage} />
                    <Route path="/about" component={AboutPage} />
                    <Route path="/contact_us" component={ContactPage} />

                    <Route path="/available" component={AvailablePage} />
                    <Route path="/recovery" component={PasswordRecoveryPage} />
                    <Route path="/new_password" component={PasswordSetPage} />
                    <Route path="/no-longer" component={NoLongerPage} />
                    <Route exact path="/404" component={NotFoundPage} />
                    <Route path="/info" component={InfoPage} />
                    {/* <Route path="/tariffs" component={TariffsPage} /> */}
                    <Route path="/avoid-fraud" component={AvoidScamsPage} />
                    <Redirect path="/license" to="/" />
                    <Route exact={true} path="/client/index" component={ExchangePage} />
                    <Redirect to="/404" />
                </Switch>
            </BrowserRouter>
        </>

}

export default App;
