import {Component, default as React} from "react";
import {StringUtils} from "../../utils/StringUtils";

export interface IInputEmailProps {
    visible: boolean
}

export class ExchangeStep1InputEmail extends Component<IInputEmailProps, {}> {
    state = {
        isValidEmail: true
    }

    onInputEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
        const isValid = StringUtils.isEmail(event.target.value);
        if(this.state.isValidEmail !== isValid)
            this.setState({isValidEmail: isValid});
    }

    render() {
        return (
            this.props.visible &&
            <>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form__item">
                            <input name="email"  id="email" type="email" className="input input_small" placeholder="Email" onInput={this.onInputEmail} />
                            {!this.state.isValidEmail && <span className="erorrs">Please enter a valid email!</span>}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}