import {Component, createRef, default as React} from "react";
import {ExchangeStepsLine} from "./ExchangeStepsLine";
import {ExchangeStep3Form} from "./ExchangeStep3Form";
import ExchangeSummaryDetails from "./ExchangeSummaryDetails";
import {ExchangeSession} from "../../models/ExchangeSession";
import {useHistory} from "react-router-dom";
import {UrlUtils} from "../../utils/UrlUtils";
import {ResultInfo} from "./ResultInfo";
import {DefaultValues} from "../../models/DefaultValues";

export class ExchangeStep4RedirectContent extends Component {
    state = {
        fiatCurrency: "EUR",
        cryptoCurrency: "BTC",
        wallet: '',
        fiat_amount: 0,
        error_msg: false,
        ex_transaction_id: false
    }

    exchange_summary: React.RefObject<ExchangeSummaryDetails> = createRef();

    public componentDidMount(): void {
        ExchangeSession.redirectIfWrongStep("4");

        const state = ExchangeSession.getExchangeState();
        console.log("STATE", state);
        if(state) {
            this.setState(state);
            this.redirect();
        }
        else
        {
            ExchangeSession.data = null;
            const history = useHistory();
            history.goBack();
        }
    }

    redirect = async () =>
    {
        let session_data = ExchangeSession.data;

        if(session_data['payment_token'] && session_data['ex_transaction_id'])
        {
            ExchangeSession.saveDataParam('step', session_data['self_processing'] ? "4.2" : "5");

            if(session_data['self_processing'])
                UrlUtils.redirectTo("exchange?step=4.2");
            else
                UrlUtils.redirectTo(DefaultValues.PAY + "payments/checkout.htm?token=" + session_data['payment_token']);
        }
        else
            this.setState({error_msg: "Invalid data! Please contact support."})

    }

    render = () => (
        <div className="body__steps">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 steps__dots_bg">
                        <ExchangeStepsLine step_id={3} />
                    </div>
                    <div className="col-lg-10">
                        <div className="steps__box steps__box_boxing">
                            <div className="row no-gutters">
                                <div className="steps__part_big">
                                    <div className="steps__box_light">
                                        {this.state.error_msg ? <ResultInfo message_head={"SORRY, YOUR PAYMENT FAILED."} message={this.state.error_msg} success={false}/> : <ResultInfo message_head={"Redirecting"} message="to card processing..." success={true}/>}
                                    </div>
                                </div>
                                <div className="steps__part_small">
                                    <div className="steps__box_dark">
                                        <ExchangeSummaryDetails ex_transaction_id={this.state.ex_transaction_id} wallet={this.state.wallet} ref={this.exchange_summary} fiat_amount={this.state.fiat_amount} crypto_currency={this.state.cryptoCurrency} fiat_currency={this.state.fiatCurrency} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
