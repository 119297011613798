import * as React from "react";
import ExchangeFiatRates from "./ExchangeFiatRates";
import ExchangeCryptoRates from "./ExchangeCryptoRates";

export interface AssetsContentProps {
    classParam?: string;
}


export const AssetsContent = (props: AssetsContentProps) =>
    <section className={"assets light " + props.classParam}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12 text-sm-center text-left">
                    <h2 className="title assets__title">Wisenex Supported Assets</h2>
                    <p className="assets__slogan slogan">It has never been so simple</p>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6">
                    <ExchangeFiatRates />
                </div>
                <div className="col-xl-6">
                    <ExchangeCryptoRates />
                </div>
            </div>
        </div>
    </section>