import {Component, default as React} from "react";
import {ExClient} from "../../models/ExClient";
import {ExchangeSession} from "../../models/ExchangeSession";
import {StringUtils} from "../../utils/StringUtils";

export interface IInputWalletProps {
    is_authorized: boolean,
    is_ref: boolean
}

export class ExchangeStep1InputWallet extends Component<IInputWalletProps, {}> {
    state = {
        has_wallet: false,
        wallet: ""
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps: Readonly<IInputWalletProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.is_authorized != this.props.is_authorized || prevProps.is_ref != this.props.is_ref)
            this.load();

        console.log("WALLETT", this.state.has_wallet, this.props.is_authorized, this.props.is_ref)
    }

    load = async () =>
    {
        if(!this.state.has_wallet) {
            if (this.props.is_ref)
                this.setState({has_wallet: true});
            else if (this.props.is_authorized) {
                if (await ExClient.hasWallet()) {
                    this.setState({has_wallet: true});
                }
            }
        }
    }

    onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({wallet: event.target.value});
    }

    render() {
        return (
            ((this.props.is_ref || this.props.is_authorized) && this.state.has_wallet) &&
            <>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form__item">
                            <input name="wallet" type="text" className="input input_small" placeholder="Your wallet address"  value={this.state.wallet} onChange={this.onInput}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}