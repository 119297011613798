import {Component, default as React} from "react";
import {ApiData} from "../../models/ApiData";
import {ExClient} from "../../models/ExClient";
import { PaymentType } from '../../models/types/ExPaymentType'

interface IExchangeStep1PayTypeSelectorProps {
    onSelect: (payment: PaymentType) => void
}

interface IState {
    payment_systems: PaymentType[]
    selected: PaymentType
    is_loading: boolean
}

const defaultPayment = { id: '0', name: '', title: '' }

export class ExchangeStep1PayTypeSelector extends Component<IExchangeStep1PayTypeSelectorProps, IState> {
    constructor(props: IExchangeStep1PayTypeSelectorProps) {
        super(props)
        this.state = {
            payment_systems: [],
            selected: defaultPayment,
            is_loading: true
        }
    }

    async componentDidMount():  Promise<void> {
        let pay_types = await ApiData.publicApiRequest({"payment_system_id": ExClient.payment_id, "shop_id": ExClient.shop_id }, "get_payment_types");
        if(pay_types.success) {
            const payment_systems = pay_types.data.reduce((acc: PaymentType[], pay_type: PaymentType) => {
                if (pay_type.name === 'card') {
                    acc.push({ ...pay_type, name: 'visa' })
                    acc.push({ ...pay_type, name: 'mastercard' })
                    acc.push({ ...pay_type, name: 'maestro' })
                } else {
                    acc.push(pay_type)
                }
                return acc
            }, [])

            let selected = payment_systems.find((el: PaymentType) =>
              el.id === ExClient.payment_id/* && el.name === ExClient.payment_name*/)
            if (!selected) {
                selected = payment_systems.length ? payment_systems[0] : defaultPayment
            }

            this.setState({
                payment_systems: payment_systems,
                is_loading: false
            })

            if (!['visa', 'mastercard', 'maestro'].includes(selected.name)) {
                this.onSelect(selected)
            }
        }
    }

    onSelect = (selected: PaymentType) => {
        if (this.state.selected.name !== selected.name) {
            this.setState({ selected })
            this.props.onSelect(selected)
        }
    }

    render = () => (
        !this.state.is_loading &&
        <div className="steps__box_row-img d-md-flex align-items-center">
            {
                this.state.payment_systems.map((d: PaymentType, index: number) =>
                    <button
                      key={index}
                      onClick={() => this.onSelect(d)}
                      className={"steps__box_card d-flex align-items-center justify-content-center " + (d.name === this.state.selected.name ? "active" : "")}
                    ><img src={"img/" + d.name + "_select_logo.png"} alt={d.name} className="img-fluid"/></button>
                )
            }
        </div>
    );
}