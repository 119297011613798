
const DefaultValuesDEV = {
    HOME: "https://dev.wisenex.com/",
    CLIENT: "https://dev.wisenex.com/client/",
    PAY: "https://devpay.wisenex.com/",
    SECURE: "https://devsecure.wisenex.com/"
};

const DefaultValuesProd = {
    HOME: "https://wisenex.com/",
    CLIENT: "https://wisenex.com/client/",
    PAY: "https://pay.wisenex.com/",
    SECURE: "https://secure.wisenex.com/"
};

export var DefaultValues = DefaultValuesProd;

export function initVars(is_dev: boolean) {
    if (is_dev) {
        DefaultValues = DefaultValuesDEV;
    }
}
