import {Component, default as React} from "react";
import SignUpForm from "../signup/SignUpForm";
import {Promo} from "../login/Promo";
import {PromoContacts} from "./PromoContacts";
import RecoveryForm from "./RecoveryForm";
import RecoverySentText from "./RecoverySentText";
import RecoveryNewPasswordForm from "./RecoveryNewPasswordForm";

interface IRecoveryContentProps {
    onSubmit: (data: any) => void,
    email: string,
    token?: string
}

export const  RecoveryContent = (props: IRecoveryContentProps) => {
    let form;
    if(props.token != null)
        form = RecoveryNewPasswordForm({onSubmit: props.onSubmit});
    else if(props.email.length > 0)
        form = RecoverySentText(props.email);
    else
        form = RecoveryForm({onSubmit: props.onSubmit})

    return (
    <div className="body__steps register body__steps_recovery">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="steps__box">
                        <div className="row no-gutters">
                            <div className="steps__part_big steps__part_big_bg">
                                <div className="steps__box_light">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h3 className="steps__box_title">PASSWORD RECOVERY</h3>
                                        </div>
                                    </div>

                                    {form}

                                </div>
                            </div>


                            <PromoContacts/>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}