import {Component, default as React} from "react";
import OptionsApiData from "../common/OptionsApiData";
import {ButtonCancel} from "./ButtonCancel";
import {ExchangeStep1InputEmail} from "./ExchangeStep1InputEmail";
import {ExchangeStep1InputWallet} from "./ExchangeStep1InputWallet";
import {WarningInfo} from "../WarningInfo";
import {ExClient} from "../../models/ExClient";
import {UrlUtils} from "../../utils/UrlUtils";
import ReCAPTCHA from "react-google-recaptcha";
import {TermsAndConditionsLabel} from "./TermsAndConditionsLabel";
import {ExchangeStep1PayTypeSelector} from "./ExchangeStep1PayTypeSelector";
import { PaymentType } from '../../models/types/ExPaymentType'

export interface IStep1Props {
    fiatCurrency: string,
    cryptoCurrency: string,

    fiat_amount: number,
    crypto_amount: number,

    onInputFiat:   (event: React.ChangeEvent<HTMLInputElement>) => void;
    onInputCrypto:   (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeFiat:  (event: React.ChangeEvent<HTMLSelectElement>) => void;
    onChangeCrypto:  (event: React.ChangeEvent<HTMLSelectElement>) => void;

    onSubmit:  (event: React.FormEvent<HTMLFormElement>) => void;
    onPaymentChange: () => void
    is_authorized: boolean,
    is_loading: boolean
}

export class ExchangeStep1Form extends Component<IStep1Props, {}>{
     isRef = ExClient.shop_id || UrlUtils.getUrlParam('s_id')? true: false;

    private fiat_select: React.RefObject<HTMLSelectElement> = React.createRef();            // TODO: think how to use only one selector
    private fiat_select_mobile: React.RefObject<HTMLSelectElement> = React.createRef();

     state = {
         payment_id: ExClient.payment_id
    }

    onSelectPayType = (payment: PaymentType) => {
        console.log("SELECTED", payment.id)
        ExClient.payment_id = payment.id
        ExClient.payment_name = payment.name
        //EventUtils.bus.publish(EventUtils.updateSelectorList({type: "fiat", payment_id: payment.id}));
        this.setState({ payment_id: payment.id })
        this.props.onPaymentChange()
    };

     onFiatListUpdate = () =>
     {
         let ev = new Event('change', { bubbles: true});
         this.fiat_select.current.dispatchEvent(ev);
         this.fiat_select_mobile.current.dispatchEvent(ev);
     }



    render = () => (
        <>
            <h3 className="steps__box_title">Payment method</h3>

            <ExchangeStep1PayTypeSelector onSelect={this.onSelectPayType}  />

            <form onSubmit={this.props.onSubmit}>
                <div className="steps__form">
                    <div className="row">
                        <div className="col-lg-6">
                            <label className="steps-form__labels labels">Amount to Spend</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 padding_right_30">
                            <div className="form__item">
                                <input name="fiat_input" type="text" className="input input_small"
                                       value={this.props.fiat_amount} onChange={this.props.onInputFiat}/>
                            </div>
                            <div className="form__item d-lg-none d-block">

                            <select ref={this.fiat_select_mobile} name="fiat_select" className="input select_light input_small "
                                        onChange={this.props.onChangeFiat} value={this.props.fiatCurrency}>
                                    <OptionsApiData type={'fiat'} param={this.state.payment_id} key_equals_value={true} onReloaded={this.onFiatListUpdate}/>
                            </select>
                            </div>
                            <div className="form__item">
                                <label className="steps-form__labels labels">Coins to Receive</label>
                                <input type="text" className="input input_small" value={this.props.crypto_amount}
                                       onChange={this.props.onInputCrypto}/>
                            </div>
                            <div className="form__item form__item_no_label d-lg-none d-block">
                                <select name="crypto_select" className="input select_light input_small"
                                        onChange={this.props.onChangeCrypto} value={this.props.cryptoCurrency}>
                                    <OptionsApiData type={'crypto'} key_equals_value={true}/>

                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6 padding_left_30 d-lg-block d-none">
                            <div className="form__item">

                                <select ref={this.fiat_select} name="fiat_select" className="input select_light input_small "
                                        onChange={this.props.onChangeFiat} value={this.props.fiatCurrency}>
                                    <OptionsApiData type={'fiat'} param={this.state.payment_id} key_equals_value={true} onReloaded={this.onFiatListUpdate}/>
                                </select>
                            </div>
                            <div className="form__item form__item_no_label">
                                <select name="crypto_select" className="input select_light input_small"
                                        onChange={this.props.onChangeCrypto} value={this.props.cryptoCurrency}>
                                    <OptionsApiData type={'crypto'} key_equals_value={true}/>

                                </select>
                            </div>
                        </div>
                    </div>

                    {!this.props.is_loading &&
                        <>
                            <ExchangeStep1InputEmail visible={!this.props.is_authorized}/>
                            <ExchangeStep1InputWallet is_authorized={this.props.is_authorized} is_ref={this.isRef}/>

                            {
                                this.isRef &&
                                <WarningInfo>
                                    Wisenex is not affiliated with any third party platforms (i.e. Amazon, Airbnb,
                                    gaming sites, investment platforms, etc).
                                    When cryptocurrency is sent to an external platform, the transaction is irrevocable
                                    and non-refundable. No changes can be made as soon as the transaction is completed.
                                </WarningInfo>
                            }
                        </>
                    }

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="step__box_agree">
                                <div className="custom-control custom-checkbox">
                                    <input id="agree" name="agree_terms" defaultChecked={false}  type="checkbox" className="custom-control-input" />
                                    <TermsAndConditionsLabel />
                                </div>
                            </div>
                            <div className="step__box_agree">
                                <div className="custom-control custom-checkbox">
                                    <input id="old" name="agree_age" defaultChecked={false}  type="checkbox" className="custom-control-input"  />
                                    <label className="custom-control-label" htmlFor="old">
                                        Yes, I am over the age of 18
                                    </label>
                                </div>
                            </div>

                        <div className="step__box_cap">
                            {!this.props.is_authorized && <ReCAPTCHA sitekey="6LeIYMMUAAAAAKWkyxt9cOKUVEI8fUedZl1x4CA2"/>}
                        </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="steps__form_footer">
                                <button type="submit" className="button button_bg_red steps-form__submit">Continue
                                </button>
                                <ButtonCancel/>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
