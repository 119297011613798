import * as React from "react";
import {HeaderSimple} from "../../components/HeaderSimple";
import "./index.css"

export const NoLongerPage = () =>
    <>
        <HeaderSimple onlyLogo isDark={false}/>
        <div className="container">
            <div className="not-found">
                <br/>
                <h1 className="title no_longer-text">
                    The cryptocurrency exchanger is no longer operating.
                </h1>
                <br/>
            </div>
        </div>
    </>
