import {Component, default as React} from "react";
import useForm from 'react-hook-form'
import {ButtonCancel} from "./ButtonCancel";
import OptionsApiData from "../common/OptionsApiData";
import {TermsAndConditionsLabel} from "./TermsAndConditionsLabel";
import {ApiData} from "../../models/ApiData";

export interface IExchangeStep2FormProps {
    onSubmit:  (values:any) => void;
}

export const ExchangeStep2Form  = (props: IExchangeStep2FormProps) =>
{
    const { handleSubmit, register, errors } = useForm();

    const onCountriesLoaded = () =>
    {
        ApiData.clientApiRequest({}, "get_country").then((data) => {
            if(data.success)
                $("#country_code").val(data.data['code']);
        });
    }


    return (
            <>
                <h3 className="steps__box_title">
                    <span className="d-inline-block position-relative">
                        Add Personal Information
                    </span>
                </h3>

                <form onSubmit={handleSubmit(props.onSubmit)}>
                    <div className="steps__form">
                        <div className="steps__form_boxing">
                            <div className="row">
                                <div className="col-lg-6 padding_right_30">
                                    <div className="form__item">
                                        <input name="first_name" type="text" className="input input_small" placeholder="First Name" ref={register({ required: true, minLength: 2, pattern: /^[a-zA-Z\s]*$/ })}/>
                                        { errors.first_name && (errors.first_name.type == "pattern" ? <span className="erorrs">This field should be in Latin letters!</span> : <span className="erorrs">This field is required!</span>)}
                                    </div>

                                </div>
                                <div className="col-lg-6 padding_left_30">
                                    <div className="form__item">
                                        <input name="last_name" type="text" className="input input_small" placeholder="Last Name" ref={register({ required: true, minLength: 2, pattern: /^[a-zA-Z\s]*$/ })}/>
                                        { errors.last_name && (errors.last_name.type == "pattern" ? <span className="erorrs">This field should be in Latin letters!</span> : <span className="erorrs">This field is required!</span>)}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 padding_right_30">
                                    <div className="form__item">
                                        <input name="middle_name" type="text" className="input input_small" placeholder="Middle Name" />
                                    </div>
                                </div>
                                <div className="col-lg-6 padding_left_30">
                                    <div className="form__item steps-form__control">
                                        <label className="steps-form__labels labels">Sex</label>
                                        <div className="steps-form__control_wrap d-flex">
                                            <div className="custom-control custom-radio">
                                                <input name="sex" value="1" type="radio" id="Male"  className="custom-control-input" defaultChecked ref={register({ required: true })} />
                                                    <label className="custom-control-label" htmlFor="Male">Male</label>
                                            </div>
                                            <div className="custom-control custom-radio">
                                                <input name="sex" value="0" type="radio" id="Female"  className="custom-control-input" ref={register({ required: true })} />
                                                    <label className="custom-control-label" htmlFor="Female">Female</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 padding_right_30">
                                    {/* <div className="form__item">
                                        <input name="date_birth" type="text" className="input input_small datepicker-date" placeholder="Date of Birth" ref={register({ required: true })} />
                                        {errors.date_birth && <span className="erorrs">This field is required!</span>}
                                    </div> */}
                                     <div className="form__item">
                                        <input name="phone" type="text" className="input input_small" placeholder="Phone Number" ref={register({required: true, minLength: 6, maxLength: 18, pattern: /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/})}/>
                                        {errors.phone && <span className="erorrs">Please, enter a valid phone!</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 padding_left_30">
                                   
                                </div>
                            </div>
                        </div>
                        <h3 className="steps__box_title">Billing Address (must match your utility bill)</h3>
                        <div className="row">
                            <div className="col-lg-6 padding_right_30">
                                <div className="form__item form__item_nolabel">
                                    <div className="form__item">

                                        <select id="country_code" name="country_code" className="input input_small " ref={register({ required: true })} >
                                            <OptionsApiData type={"country"} key_equals_value={false} onLoaded={onCountriesLoaded}/>
                                        </select>

                                        {errors.country && <span className="erorrs">This field is required!</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 padding_left_30">
                                <div className="form__item">
                                    <input name="state" type="text" className="input input_small" placeholder="State" ref={register({ required: true, minLength: 2 })}/>
                                    {errors.state && <span className="erorrs">This field is required!</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 padding_right_30">
                                <div className="form__item form__item_nolabel">
                                    <input name="city" type="text" className="input input_small" placeholder="City" ref={register({ required: true, minLength: 2 })}/>
                                    {errors.city && <span className="erorrs">This field is required!</span>}
                                </div>
                            </div>
                            <div className="col-lg-6 padding_left_30">
                                <div className="form__item form__item_nolabel">
                                    <input name="zip" type="text" className="input input_small" placeholder="Zip Code" ref={register({ required: true, minLength: 4 })}/>
                                    {errors.zip && <span className="erorrs">This field is required!</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 padding_right_30">
                                <div className="form__item form__item_nolabel">
                                    <input name="address" type="text" className="input input_small" placeholder="Street Address" ref={register({ required: true, minLength: 4 })} />
                                    {errors.address && <span className="erorrs">This field is required!</span>}
                                </div>
                            </div>
                            <div className="col-lg-6 padding_left_30">
                                <div className="form__item form__item_nolabel">
                                    <input name="address2" type="text" className="input input_small" placeholder="Apt/suite" ref={register({ required: true, minLength: 1 })}/>
                                    {errors.address2 && <span className="erorrs">This field is required!</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 padding_right_30">
                                <div className="step__box_agree">
                                    <div className="custom-control custom-checkbox">
                                        <input id="agree" name="agree_terms" defaultChecked={false} type="checkbox" className="custom-control-input" ref={register({ required: true })} />
                                        <TermsAndConditionsLabel />
                                        {errors.agree_terms && <span className="erorrs">Please accept Terms &amp; Conditions!</span>}
                                    </div>
                                </div>
                                <div className="step__box_agree">
                                    <div className="custom-control custom-checkbox">
                                        <input id="old" name="agree_age" defaultChecked={false} type="checkbox" className="custom-control-input"  ref={register({ required: true })} />
                                        <label className="custom-control-label" htmlFor="old">
                                            Yes, I am over the age of 18
                                        </label>
                                        {errors.agree_age && <span className="erorrs">Please accept that you are over 18 years old!</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="steps__form_footer">
                                    <button type="submit" className="button button_bg_red steps-form__submit">Continue</button>
                                    <ButtonCancel />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
}