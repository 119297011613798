import {Component, default as React} from "react";
import AvailableInCountry from "../index/AvailableInCountry";
import {AvailableCountriesList} from "./AvailableCountriesList";
import {UrlUtils} from "../../utils/UrlUtils";
import {ApiData} from "../../models/ApiData";
import {ExClient} from "../../models/ExClient";

export class AvailableContent extends Component {

    onClickRegister = async (e: React.FormEvent<HTMLFormElement>) =>
    {
        e.preventDefault();

        var data = $(e.currentTarget).serializeArray().reduce(function(obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        let result = await ApiData.clientApiRequest({email: data['email']}, 'is_registered');
        if(result.success && result.data)
        {
            await ExClient.logout()
            UrlUtils.redirectTo("login?email=" + data['email'] + "#exists");
        }
        else
            UrlUtils.redirectTo("sign_up?email=" + data['email']);
    }

    render = () => (
        <>
            <div className="available">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <div className="avialibe__text">
                                <AvailableInCountry />
                            </div>
                            <h2 className="title available__title">Wisenex is available<br /> in 166 countries</h2>
                            <div className="available__form">
                                <form onSubmit={this.onClickRegister} >
                                    <input type="email"  name={"email"} className="input available__input input-light text-center" placeholder="Enter your email" />
                                    <button type={"submit"} className="button button_bg_red available__submit">Register</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AvailableCountriesList />

        </>
    );
}
