import {Component, default as React} from "react";

export class CardsContent extends Component {
    render = () => (
        <>
            <section className="works">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="title title__works">How it works</h2>
                            <p className="slogan slogan__works">It is easy and fast to start using the card, even if it doesn’t seem so. </p>
                        </div>
                    </div>
                    <div className="row aff__marker_dots">
                        <div className="col-lg-3">
                            <div className="aff__marker_item text-center">
                                <div className="aff__marker_ic aff__marker_ic_1">
                                    <img src="img/cards/icons/02/step_1.svg" alt="icons" />
                                </div>
                                <h3 className="aff__marker_caption">GET WISENEX CARD</h3>
                                <p className="aff__marker_des">The card can be mailed to you if you want. The only thing you should do is to verify your account and fill in the application. </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="aff__marker_item aff__marker_item_center text-center">
                                <div className="aff__marker_ic aff__marker_ic_2">
                                    <img src="img/cards/icons/02/step_1.svg" alt="icons" />
                                </div>
                                <h3 className="aff__marker_caption">LOADING GOES NEXT</h3>
                                <p className="aff__marker_des">Convert the money you have onto your wallet and load it to the card. The system will convert it to Euros so you can easily spend them at your convinience.</p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="aff__marker_item text-center">
                                <div className="aff__marker_ic aff__marker_ic_3">
                                    <img src="img/cards/icons/02/step_1.svg" alt="icons" />
                                </div>
                                <h3 className="aff__marker_caption">NOW USE WHAT YOU HAVE GOT!</h3>
                                <p className="aff__marker_des">Use your card as a regular ATM card for everyday needs, shopping and in the ATMs worldwide. No borders, no limits.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <a href="login" className="button button_bg_red main-aff__butt">Notify me</a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="shop light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2 className="title title__shop">Shop everywhere with Wisenex Bitcoin card</h2>
                        </div>
                        <div className="col-lg-6">
                            <h3 className="shop__caption">Use Wisenex prepaid BTC card for online or offline shopping or to withdraw cash from the ATM.</h3>
                            <ul className="list shop__list">
                                <li className="shop__list_item">Shop in millions of stores worldwide (wherever credit cards are accepted)</li>
                                <li className="shop__list_item">Make cash withdrawals from any ATM in the world</li>
                                <li className="shop__list_item">Shop and pay your bills online with one simple click</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="way">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="title title__way text-center">Access Your Assets simple secure, <br />fast and trustworthy </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="way__item way__item_1">
                                <h3 className="way__title">CHIP & PIN ACCESS</h3>
                                <p className="way__des">EMV chip, PIN code and all the specifications provide fast payments, as well as security up to the highest world standards.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 offset-lg-1 d-flex justify-content-center col-md-6">
                            <div className="way__item way__item_2">
                                <h3 className="way__title">FORGET YOUR INTEREST CHARGES </h3>
                                <p className="way__des">Payment interest? Forget these words! You load your card from your wallet directly so the money is converted with no interest charge.</p>
                            </div>
                        </div>
                        <div className="offset-lg-1 col-lg-3 col-md-6">
                            <div className="way__item way__item_3">
                                <h3 className="way__title">LOW COMMISSION RATE</h3>
                                <p className="way__des">Our commission is kept at the minimum level. It is a part of our  clients policy. We are providing you with the best service since our establishment.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="way__item way__item_4">
                                <h3 className="way__title">WORLDWIDE DELIVERIES WITH NO DELAYS </h3>
                                <p className="way__des">We mail cards directly to you even to most remote locations. We value your time and provide the service with minimum delays and lowest rates.  </p>
                            </div>
                        </div>
                        <div className="col-lg-4 offset-lg-1 d-flex justify-content-center col-md-6">
                            <div className="way__item way__item_5">
                                <h3 className="way__title">HAVE ABSOLUTE CONTROL</h3>
                                <p className="way__des">You can do all the operations with your card from your card portal, even block it if it required. It is  easy , secure and safe.</p>
                            </div>
                        </div>
                        <div className="offset-lg-1 col-lg-3 col-md-6">
                            <div className="way__item way__item_6">
                                <h3 className="way__title">NOTIFICATIONS TO IMPROVE OUR SERVICE</h3>
                                <p className="way__des">We value your time and trust so we are about to improve our service and provide you with the opportunity to get the notifications when your card is charged.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="fees light">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4">
                            <h2 className="title title__fees">Transparent Low Fees</h2>
                            <p className="fees__des">Use Wisex prepaid BTC card for online or offline shopping or withdraw cash in ATM.</p>
                            <a href="login" className="button button_bg_red fees__button">See card fees & costs list</a>
                        </div>
                        <div className="col-xl-8 clearfix">
                            <div className="table-responsive table__fees">
                                <table className="table table__countries table-borderless table-striped">
                                    <tr>
                                        <th>Action</th>
                                        <th>FEES</th>
                                        <th>DAILY LIMIT</th>
                                        <th>weekly LIMIT</th>
                                    </tr>
                                    <tr>
                                        <td>OS Domestic</td>
                                        <td>€0.60</td>
                                        <td> <span className="text-warning">€2,500</span> </td>
                                        <td> <span className="text-danger">€10,000</span> </td>
                                    </tr>
                                    <tr>
                                        <td>POS International</td>
                                        <td>€1.50</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>ATM Withdrawals Domestic</td>
                                        <td>€1.50</td>
                                        <td> <span className="text-warning">€820</span> </td>
                                        <td> <span className="text-danger">€6,000</span> </td>
                                    </tr>
                                    <tr>
                                        <td>ATM Withdrawals International</td>
                                        <td>€2.50</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>ATM Balance Enquiry</td>
                                        <td>€2.50</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>ATM / POS Decline</td>
                                        <td>€0.50</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>Currency Conversion International</td>
                                        <td>€0.50</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td>Card Load</td>
                                        <td>1.00%</td>
                                        <td><span className="text-warning">€2,500</span></td>
                                        <td>-</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="benefits benefits-why">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2 className="title title__benefits">Why Us?</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="benefits__item text-center">
                                <img src="img/cards/icons/06/01.svg" alt="icons" className="benefits__ic" />
                                    <h3 className="benefits__title">USE EVERYWHERE</h3>
                                    <p className="benefits__des">Debit cards are accepted?  Use your card there! There’s an ATM around the corner? Withdraw cash in two clicks. </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits__item text-center">
                                <img src="img/cards/icons/06/02.svg" alt="icons" className="benefits__ic" />
                                    <h3 className="benefits__title">LOW RATES AND EASY PAYMENTS</h3>
                                    <p className="benefits__des">Buy BTC and LTC at the lowest rates and enjoy a simple, secure access anywhere in the world.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits__item text-center">
                                <img src="img/cards/icons/06/03.svg" alt="icons" className="benefits__ic" />
                                    <h3 className="benefits__title">SECURITY AND SPEED. </h3>
                                    <p className="benefits__des">We use the highest cryptography standards to keep your money safe.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits__item text-center">
                                <img src="img/cards/icons/06/04.svg" alt="icons" className="benefits__ic" />
                                    <h3 className="benefits__title">ABSOLUTE COVERAGE. </h3>
                                    <p className="benefits__des">Our services are offered in more than 100 countries and we keep expanding!  </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits__item text-center">
                                <img src="img/cards/icons/06/05.svg" alt="icons" className="benefits__ic" />
                                    <h3 className="benefits__title">FAST AND EASY VERIFICATION </h3>
                                    <p className="benefits__des">No-delay transactions. Easy verification. </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="benefits__item text-center">
                                <img src="img/cards/icons/06/06.svg" alt="icons" className="benefits__ic" />
                                    <h3 className="benefits__title">The highest standards of CS service</h3>
                                    <p className="benefits__des">Our CS team is always at hand. Have questions? Just contact us!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ask">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="text-center ask__title title">Cryptocurrency Wallets FAQ</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ask__panel">
                                <div className="ask__heading">
                                    <h4 className="ask__panel_title">
                                        <a data-toggle="collapse" href="#collapse1" className="ask__link" aria-expanded="true">OK, GUYS, I GOT YOU, I WANT TO HAVE A PREPAID BITCOIN CARD. WHAT SHOULD I DO FIRST?</a>
                                    </h4>
                                </div>
                                <div id="collapse1" className="ask__collapse collapse show">
                                    <div className="ask__body">
                                        <p className="ask__text">You want to get a card? It’s easy! Just log into your account and go to the ”Card” section. Follow the instructions and place an orde. In case you do not have a Wisenex account, the system will ask you to register. We made the process easy because we value your time. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ask__panel">
                                <div className="ask__heading">
                                    <h4 className="ask__panel_title">
                                        <a data-toggle="collapse" href="#collapse2" className="ask__link">I WANT TO HAVE A CARD BUT I DON'T THINK THE IDENTITY VERIFICATION IS NECESSARY. SO WHAT SHOULD I DO?</a>
                                    </h4>
                                </div>
                                <div id="collapse2" className="ask__collapse collapse">
                                    <div className="ask__body">
                                        <p className="ask__text">According to the policy of the company we ask all our clients to go through a simple verification procedure if  they apply for a Wisenex card. The verification is your insurance that we follow the KYC regulations as well as the banking regulations established by the state. This ensures your protection and safety of the transactions. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ask__panel">
                                <div className="ask__heading">
                                    <h4 className="ask__panel_title">
                                        <a data-toggle="collapse" href="#collapse3" className="ask__link">I WOULD LIKE TO KNOW WHAT CURRENCIES ARE SUPPORTED. </a>
                                    </h4>
                                </div>
                                <div id="collapse3" className="ask__collapse collapse">
                                    <div className="ask__body">
                                        <p className="ask__text">We are sorry to say but at the moment only Euros are accepted and supported. </p>

                                    </div>
                                </div>
                            </div>
                            <div className="ask__panel">
                                <div className="ask__heading">
                                    <h4 className="ask__panel_title">
                                        <a data-toggle="collapse" href="#collapse4" className="ask__link">OK, IT IS CLEAR WITH THE CURRENCY. WHAT ABOUT THE COUNTRIES?</a>
                                    </h4>
                                </div>
                                <div id="collapse4" className="ask__collapse collapse">
                                    <div className="ask__body">
                                        <p className="ask__text">For now it is possible to issue a card for the European Economic Area only. We are working on providing you better services. Please, follow the updates.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="ask__panel">
                                <div className="ask__heading">
                                    <h4 className="ask__panel_title">
                                        <a data-toggle="collapse" href="#collapse5" className="ask__link">COULD YOU PLEASE EXPLAIN CLEARLY WHAT IS THE DIFFERENCE BETWEEN A PREPAID DEBIT CARD AND A CREDIT CARD?</a>
                                    </h4>
                                </div>
                                <div id="collapse5" className="ask__collapse collapse">
                                    <div className="ask__body">
                                        <p className="ask__text">In two words by using a credit card you borrow money (with interest). Using a Wisenex prepaid debit card, you use your money (without interest) that you have loaded in advance onto the card.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ask__panel">
                                <div className="ask__heading">
                                    <h4 className="ask__panel_title">
                                        <a data-toggle="collapse" href="#collapse6" className="ask__link">I WANT TO USE YOUR CARD AROUND THE WORLD. IS IT POSSIBLE?</a>
                                    </h4>
                                </div>
                                <div id="collapse6" className="ask__collapse collapse">
                                    <div className="ask__body">
                                        <p className="ask__text">Sure! It is one of the main advantages of a Wisenex card. It is possible not just to use it anywhere around the world, but to withdraw money from any ATM. Cool, isn’t it?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cards-debit light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <h2 className="title title__cards-debit">LTC Debit Card for everyday purchases</h2>
                            <p className="slogan__cards-debit">A reloadable cryptocurrency card from Wisenex allows you to enjoy control over money and expenses. Everyday purchases, online shopping withdrawals – everything is possible with a prepaid card.</p>
                            <a href="login" className="button button_bg_red cards-debit__submit">Notify me</a>
                        </div>
                        <div className="col-lg-5">
                            <img src="img/card.png" alt="card" className="img-fluid cards-debit__img" />
                        </div>
                    </div>
                </div>
            </section>
        
        </>
    );
}