import * as React from "react";

export const AvailableMap = () => {
    return (
        <section className="map light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <img src="img/map.png" alt="map" className="map__img img-fluid" />
                    </div>
                </div>
            </div>
        </section>
    )
}